
button{
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: none;
    background: transparent;
}
.detail-info{
    padding: 15px 15px 12px 15px;
    background: #ffffff;
    margin-bottom: 10px;
    .price-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 14px;
        .lt{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .yj-price{
                color: #999999;
                line-height: 20px;
                text-decoration: line-through;
                padding-left: 8px;
            }
        }
        .rt{
            .num{
                background: #FCF0E9;
                line-height: 20px;
                font-size: 11px;
                color: #FC6C21;
                padding: 0 5px;
            }
        }
    }
    .title{
        text-align: start;
        padding-bottom: 5px;
    }
    .time{
        font-size: 13px;
        line-height: 18px;
    }
}
.detail-face{
    background: #ffffff;
    padding: 0 15px;
    margin-bottom: 10px;
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F1F1F1;
        padding: 10px 0;
        .lt{
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .txt{
                color: #333333;
                padding-left: 15px;
                width: 250px;
            }
        }
        .rt{
            .icon-right{
                display: block;
                width: 14px;
                height: 14px;
                background: url(../../assets/icons/icon-right.png) center/100%,100% no-repeat;
            }
        }
    }
    .item:last-child{
        border: none;
    }
}
.detail-tabs{
    .intro{
        padding: 15px;
        background: #ffffff;
    }
    .ms-intro{
        img{
            width: 100%;
        }
    }
    .detail-teacher{
        background: #ffffff;
        padding: 0 15px;
        .item{
            display: flex;
            justify-content: flex-start;
            padding: 15px 0;
            .lt{
                .lt-img{
                   width: 54px;
                   height: 54px; 
                   border-radius: 50%;
                }
                margin-right: 16px;
            }
            .rt{
                display: flex;
                flex-direction: column;
                .tp{
                    display: flex;
                    align-items: center;
                    margin-top: 6px;
                    .name{
                        font-size: 16px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 22px;
                        padding-right: 9px;
                    }
                    .short-intor{
                        padding: 2px 5px;
                        background: #FFF9F5;
                        border-radius: 2px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #FC6C21;
                        line-height: 20px;
                    }
                }
                .star{
                    display: flex;
                    align-items: center;
                    .icon-star{
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: url(../../assets/icons/icon-stars-on.png) center/100% 100% no-repeat;
                        margin-left: 2px;
                    }
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: #999999;
                    line-height: 17px;
                    margin-top: 10px;
                    span{
                        padding-left: 4px;
                    }
                }
                .intor-txt{
                    font-size: 14px;
                    color: #999999;
                    line-height: 22px;
                    margin-top: 10px;
                }
            }
        }
    }
}
.lesson{
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
    border-radius: 4px;
    .tit-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon-right{
            width: 16px;
            height: 16px;
            background: url(../../assets/icons/icon-right.png) center/100% 100% no-repeat;
        }
        .tit{
            font-size: 15px;
            line-height: 21px;
            font-weight: 500;
            padding: 10px 15px;
        }
        .tit-01{
            font-weight: 400;
        }
    }
}
