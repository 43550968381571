
.place{
    height: 100px;
}
.code-box {
    padding: 16px;
    margin: 12px 10px 0 10px;
    border-radius: 8px;
    background: #fff;
    box-sizing: border-box;
    font-weight: 500;
}
.code-box-tabs {
    margin: 12px 0px 0 0px;
    border-radius: 0px;
}
.code-box-01{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.code-box .lt, .code-box .rt{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}
.code-box .code-btn{width: 124px;border-radius: 21px;border: 1px solid #F30406;color: #F51606;font-size: 14px;line-height: 36px;text-align: center;margin-top: 14px;display: flex;align-items: center;justify-content: center;}
.code-box .code-img{width: 90px;height: 90px;}
.code-box .icon-circle{display: block;width: 12px;height: 12px; background:url(https://file.huatu.com/static/miniprogram/shop/h5/components/code_icon_circle_orange.png) center/100% 100% no-repeat;margin-right: 4px;}
.code-box .icon-down{display: block;width: 16px;height: 16px; background:url(https://file.huatu.com/static/miniprogram/huatu/code_icon_down.png) center/100% 100% no-repeat;margin-right: 4px;}
.code-box .code-name{font-size: 13px;color: #666666;line-height: 18px;padding-top: 6px;width: 77px;text-align: center;}
.code-box .code-title{line-height: 20px;color: #262626;font-size: 14px;font-weight: 500;}
.code-box .code-title-01{display: flex;align-items: center;}
.code-box .code-test{margin-top: 7px;font-size: 12px;color: rgba(38, 38, 38, .5);line-height: 17px;}
