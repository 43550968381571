
.tabbar {
  button {
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 10px;
      margin-top: 5px;
    }
  }
}
