
    .countdown{
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-countdown-outside2@2x.png) no-repeat 0 0;
        background-size: cover;
        width: 171px;
        height: 20px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        .block{
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            position: relative;
            .first{
                position: absolute;
                left: 3px;
                top: 50%;
                transform: translateY(-50%);
            }
            .second{
                position: absolute;
                left: 21px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .dd {
            margin-left: 0;
        }
        .hh {
            margin-left: 50px;
        }
        .mm {
            margin-left: 45px;
        }
        .ss {
            margin-left: 44px;
        }
    }
