
.buy-box{
    width: 310px;
    height: 520px;
    .content{
        width: 100%;
        height: 720px;
        position: relative;
        overflow: hidden;
        .img{
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
        }
        .box-ewm{
            width: 160px;
            height: 100px;
            position: absolute;
            top: 450px;
            left: 75px;
            z-index:15px;
            .ewmimg{
                width: 100px;
                height: 100px;
                margin-left: 30px;
            }
            .btn-ewm{
                font-size: 13px;
                color: #FC6C21;
                font-weight: bold;
                line-height: 18px;
                text-align: center;
            }
        }
        .box-htjy{
            width: 245px;
            height: 68px;
            position: absolute;
            top: 639px;
            left: 27px;
        }
    }
    .content01{
        height: 575px;
    }
    .content02{
        height: 560px;
    }
    .content03{
        height: 481px;
    }
}
.buy-box03{
    height: 481px !important;
}
.buy-box01{
    height: 560px;
}
.buy-box02{
    height: 410px;
    overflow: hidden;
}
.teacher-qrcode{
    position: absolute;
    width: 106px;
    height: 106px;
    bottom: 10px;
    left: calc(50% - 50px);
    border-radius: 5px;
    border: 6px solid #fff;
}
.teacher-qrcode img{
    width:100%;
    height: 100%;
}
