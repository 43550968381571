
.van-tit{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDEDED;
    position: relative;
    .icon-back{
        display: block;
        width: 18px;
        height: 18px;
        background: url(../../assets/icons/icon-back.png) center/100% 100% no-repeat;
        position: absolute;
        left: 10px;
        top: 16px;
    }
}
