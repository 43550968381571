
.detail-group{
    background: #ffffff;
    padding: 9px 15px;
    margin-bottom: 10px;
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        .lt{
            width: 52px;
            .lt-img{
                width: 26px;
                height: 26px;
                border-radius: 50%;
            }
        }
        .rt{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            width: 285px;
            height: 30px;
            background: #F6F6F6;
            border-radius: 19px;
            padding-left: 8px;
            font-size: 12px;
            .time{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 10px;
            }
            /deep/.van-count-down{
                font-size: 10px;
            }
            .join-btn{
                width: 60px;
                height: 30px;
                box-sizing: border-box;
                background: #FFEBDF;
                border-radius: 18px;
                border: 1px solid #FFD5BF;
                font-size: 12px;
                color: #FC6C21;
                text-align: center;
                line-height: 30px;
            }
        }
    }
}
