
.multi_express {
	// width: 355px;
	min-height: 56px;
	margin: 0 auto;
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	overflow-y: auto;
	font-family: PingFangSC-Regular, PingFang SC;
    margin: 12px 16px 0 16px;
    // padding: 0 10px;
	.head {
		// width: 355px;
		height: 56px;
		background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/order/icon_order_multi_express.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		box-sizing: border-box;
		padding: 10px;
		.title {
			font-size: 13px;
			font-weight: 400;
			color: #FC6C21;
			line-height: 18px;
			text-align: start;
			// margin: 10px;
		}
	}
    /deep/ .van-step:last-child{
        margin-bottom: 15px;
        padding-bottom: 0 !important;
        .van-step__line {
            width: 1px !important;
            height: 70% !important
        }
    }
	.itemClass {
		padding: 5px 10px 10px 0 !important;
		&:after{
			border: none !important;
		}
		/deep/ .van-step__title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p{
				height: 18px;
				font-size: 13px;
				font-weight: 400;
				color: #4A4A4A;
				line-height: 18px;
				margin-top: 8px;
			}
		}
		/deep/ .van-step__circle{
			width: 6px;
			height: 6px;
			background: #FC6C21;
		}
		/deep/ .van-step__line{
			background-color: transparent !important;
			border: none !important;
			border-left: 1px dashed #E5E7E8 !important;
		}
	}
}
