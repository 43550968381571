
.pattern{
    width: 100%;
    // height: 341px;
    // padding: 15px 0;
    padding: 0px 0px 15px;
    overflow: hidden;
    .header{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 5px;
        box-sizing: border-box;
        padding-left: 4%;
    }
    .item {
        width: 92%;
        margin: 0 auto;
        background: #fff;
        border-radius: 8px;
        position: relative;
        
        // overflow: hidden;
        &:first-child{
            margin-bottom: 12px;
        }
        .container{
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            .section{
                box-sizing: border-box;
                padding: 0 10px;
                margin-top: 26px;
                margin-bottom: 3px;
                display: flex;
                justify-content: space-between;
                .tips{
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #666666;
                    margin-right: 8px;
                }
                .two {
                    margin-right: 0px;
                    span.color1{
                        color: #FC6C21;
                    }
                    
                }
                .qrcode{
                    width: 66px;
                    height: 66px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .qrcode_img {
                    flex-shrink: 0;
                    width: 66px;
                    height: 66px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .subTips{
                -webkit-transform-origin-x: 0;
                -webkit-transform: scale(0.90);
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                // margin-left: 9px;
                padding-left: 10px;
                width: 110%;
                box-sizing: border-box;
                &::before{
                    content: '';
                    width: 4px;
                    height: 4px;
                    background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-coin.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 0 0;
                    margin-right: 5px;
                }
            }
            .arraw{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                img{
                    width: 9px;
                    height: 21px;
                }
            }
            .box-htjy{
                width: 88.43%;
                height: 86px;
                margin: 0 auto;
                margin-top: 5px;
                margin-bottom: 16px;
                box-shadow: 0px 2px 8px 0px rgba(252, 108, 33, 0.12);
            }
        }
        .bage{
            width: 48px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/icon-mock-pop-pattern-item.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 11px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            span{
                padding-bottom: 3px;
            }
        }
    }
}
.other{
    background: #fff;
    padding-bottom: 4px;
    .item{
        background: #FFF9F6;
        margin-bottom: 12px;
        &:last-child{
            margin-bottom: 16px;
            .container{
                padding-bottom: 19px;
                .box-htjy{
                    margin-bottom: 0;
                }
            }
        }
    }
}
