
.confirm-pop {
    background: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
        width: 82.6%;
        max-width: 310px;
        min-height: 100px;
        background: transparent;
        position: relative;
        .header {
            width: 101.68%;
            max-width: 315px;
            height: 126px;
            position: relative;
            .blank {
                position: absolute;
                top: 41px;
                left: 14px;
            }
            .content {
                position: absolute;
                width: 100%;
                top: 68px;
                left: 0px;
                padding: 0 20px;
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
        &.receive-normal .header {
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/bg-pop-header-pen@2x.png)
                no-repeat 0 0;
            background-size: cover;
            .blank {
                width: 102px;
                height: 24px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-pop-blank-receive@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
        }
        &.receive-success .header {
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/bg-pop-header-tick@2x.png)
                no-repeat 0 0;
            background-size: cover;
            height: 117px;
            .blank {
                width: 72px;
                height: 24px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-pop-blank-receive-success@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .content {
                bottom: 36px;
            }
        }
        .form {
            border-radius: 8px;
            background: #fff;
            margin-top: -14px;
            z-index: 1;
            position: relative;
        }
        .close {
            position: absolute;
            bottom: -45px;
            left: 50%;
            transform: translateX(-50%);
            background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-pop-close@2x.png)
                no-repeat 0 0;
            background-size: cover;
            width: 32px;
            height: 32px;
        }
    }
}
