
.detail-info {
  background: #ffffff;
  margin-bottom: 10px;
  .price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px 8px;
    position: relative;
    .lt {
      box-sizing: border-box;
      padding-top: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .origin-price {
        color: #999999;
        line-height: 20px;
        text-decoration: line-through;
        padding-left: 8px;
      }
      .group-capsule {
        width: 87px;
        height: 20px;
        display: flex;
        align-items: center;
        margin-left: 5px;
        margin-bottom: 5px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .num {
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fe3109;
        }
      }
    }
    .lt.origin-price {
      flex-grow: 1;
      // padding-top: 3px;
      padding-left: 5px;
    }
    .rt {
      .num {
        background: #fcf0e9;
        line-height: 20px;
        font-size: 11px;
        color: #fc6c21;
        padding: 0 5px;
      }
    }
    .mock-title {
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333;
    }
    .countdown {
      color: #ff1717;
      .tit {
        display: flex;
        justify-content: flex-end;
        .icon {
          width: 84px;
          height: 14px;
          background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-spike-countdown-notStart@2x.png)
            no-repeat 0 0;
          background-size: cover;
        }
      }
    }
  }
  .spike {
    background: linear-gradient(
      90deg,
      rgba(252, 108, 33, 0) 0%,
      rgba(252, 108, 33, 0.27) 100%
    );
    margin-bottom: 8px;
  }
  .spike.end {
    background: linear-gradient(
      90deg,
      rgba(173, 173, 173, 0) 0%,
      rgba(146, 146, 146, 0.27) 100%
    );
  }
  .spike.processing .countdown .icon {
    background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-spike-countdown-processing@2x.png)
      no-repeat 0 0;
    background-size: cover;
    width: 70px;
    height: 14px;
  }
  .spike.end .countdown {
    color: #999999;
    .icon {
      background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon-spike-countdown-end@2x.png)
        no-repeat 0 0;
      background-size: cover;
      width: 70px;
      height: 14px;
    }
  }
  .title {
    text-align: start;
    padding: 0 15px 5px;
  }
  .mock-time {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 0 15px 0;
  }
  .time {
    font-size: 13px;
    line-height: 18px;
    padding: 0 15px 0;
    color: #333;
  }
  .bt-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: #999999;
    margin-top: 15px;
    padding: 0 15px 8px;
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 18px;
      position: relative;

      i {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .icon-gwc {
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_gwc.png)
          center/100% 100% no-repeat;
      }
      .icon-pf {
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_pf.png)
          center/100% 100% no-repeat;
      }
      .icon-ks {
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_ks.png)
          center/100% 100% no-repeat;
      }
      .mock-range {
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .areapop {
        position: absolute;
        top: 26px;
        right: 0;
        // min-width: 100px;
        background: #4a4a4a;
        border-radius: 5px;
        z-index: 1000;
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-bottom: 8px solid #4a4a4a;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          position: absolute;
          top: -8px;
          right: 15px;
        }
        /deep/ span {
          display: inline-block;
          color: #fff;
          padding: 3px;
        }
      }
    }
  }
}
