
@import './styles/base.scss';
@import './common/font.css';
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  -webkit-overflow-scrolling: touch;
}
