
.box{
    height: 300px;
    background: #ffffff;
    position: relative;
    .bg{
        background: #ffffff;
        height: 50px;
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
    }
}
.empty{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.img{
		width: 119px;
		height: 114px;
		margin-top: 20px;
	}
	.txt{
		font-size: 14px;
		color: #999999;
		margin-top: 24px;
		line-height: 20px;
	}
    .tel{
		margin-top: 10px;
		line-height: 20px;
        a{
            font-size: 14px;
            color: #1989fa;
        }
    }
    .btn{
        width: 140px;
        height: 38px;
        color: #ffffff;
        font-weight: bold;
        line-height: 20px;
        font-size: 14px;
        background: linear-gradient(137deg, #FF9D5B 0%, #FC6C21 100%);
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        border-radius: 22px;
        text-align: center;
        line-height: 38px;
        margin-top: 24px;
    }
}
