
    .mock-pop{
        .pop-content{
            width: 82.7%;
            margin: 0 auto;
            .header{
                position: relative;
                width: 101.7%;
                height: 118px;
                background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/mock-bg-pop-register-success.png);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 0 0;
                color: #FFFFFF;
                img {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 12px;
                    top: 38px;
                }
                .title{
                    position: absolute;
                    height: 19px;
                    top: 43px;
                    left: 20px;
                    &.success{
                        width: 128px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/txt-pop-success@2x.png) no-repeat 0 0;
                        background-size: contain;
                    }
                    &.agency{
                        width: 96px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/txt-pop-agency@2x.png) no-repeat 0 0;
                        background-size: contain;
                    }
                    &.area{
                        width: 64px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/txt-pop-area@2x.png) no-repeat 0 0;
                        background-size: contain;
                    }
                }
                .subTitle{
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    position: absolute;
                    top: 65px;
                    left: 20px;
                }

            }
            .notRegister{
                width: 100%;
                height: 90px;
                background-image: url(https://file.huatu.com/static/miniprogram/shop/h5/mock/mock-bg-pop-register-book@2x.png);
                .noRegs{
                    left: 14px;
                    top: 14px;
                }
                .title{
                    left: 20px;
                    top: 20px;
                }
                .subTitle{
                    left: 20px;
                    top: 40px;
                }
            }
            .body{
                position: relative;
                width: 100%;
                // margin-top: -9px;
                // background: #fff;
                border-radius: 8px;
                box-sizing: border-box;
                .area{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 20px 11px;
                    justify-content: space-between;
                    width: 100%;
                    overflow: hidden;
                    overflow-y: auto;
                    max-height: 278px;
                    padding-bottom: 64px;
                    .item-area{
                        width: 54px;
                        height: 24px;
                        background: rgba(0, 0, 0, 0.04);
                        border-radius: 14px;
                        text-align: center;
                        line-height: 24px;
                        margin: 0 9px;
                        margin-bottom: 18px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                    .active{
                        color: #FC6C21;
                        background: #FFEFE6;
                    }
                    .hide{
                        opacity: 0;
                    }
                }
                .confirm{
                    border-radius: 8px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 64px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    box-shadow: 0px -2px 4px 0px rgba(198, 198, 198, 0.2);
                    .btn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        width: 72%;
                        height: 40px;
                        background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
                        border-radius: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        opacity: 1;
                    }
                    .btn01{
                        opacity: 0.34;
                    }
                }
                .agency {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    flex-grow: 1;
                    .item{
                        display: flex;
                        margin-bottom: 18px;
                        .lt{
                            margin-top: 3px;
                            margin-right: 4px;
                            width: 14px;
                            height: 14px;
                        }
                        .rt{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                        }
                    }
                    .confirm01 {
                        width: 72%;
                        height: 40px;
                        background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
                        opacity: 0.34;
                        border-radius: 20px;
                        margin: 12px auto 16px auto;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                    .active01{
                        opacity: 1;
                    }
                }
            }
            .closeBtn{
                padding-top: 12px;
                display: flex;
                justify-content: center;
                img{
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
