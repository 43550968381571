
.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 14px;
    .subject {
        margin-bottom: 20px;
        .title {
            flex-grow: 1;
            margin-left: 15px;
            display: flex;
            align-items: center;
            .pre {
                color: #f00;
                margin-right: 5px;
            }
            .options {
                font-size: 12px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #999;
            }
        }
        .selection {
            width: 223px;
            height: 30px;
            background: #f8f8f8;
            border-radius: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .triangle{
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-triangle-down@2x.png) no-repeat 0 0;
                background-size: cover;
                width: 9px;
                height: 9px;
                margin-right: 16px;
            }
            .sel-input{
                background: transparent;
                flex-grow: 1;
                align-items: center;
                padding-left: 15px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
            .sel-input::-webkit-input-placeholder{
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999 !important;
            }
        }
        &:last-child{
            margin-bottom: 24px;
        }
    }
    .sex-sel {
        .sex-sel-con {
            display: flex;
            align-items: center;
            .item {
                width: 50%;
                height: 100%;
                border-radius: 15px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                transition: all 0.1s ease;
                i {
                    width: 13px;
                    height: 13px;
                    margin-right: 1px;
                }
            }
            .item.active {
                color: #fff;
                background: linear-gradient(90deg, #ff9d5b 0%, #fc6c21 100%);
            }
            .male i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-male-normal@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .male.active i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-male-active@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .female i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-female-normal@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
            .female.active i {
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/icon-female-active@2x.png)
                    no-repeat 0 0;
                background-size: cover;
            }
        }
    }
    .confirm-btn{
        width: 223px;
        height: 40px;
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/pop/bg-confirm-btn@2x.png) no-repeat 0 0;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 36px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
}
/deep/ .van-picker__confirm{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FC6C21;
}
/deep/ .van-picker__cancel{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969799;
}
/deep/ .van-picker__title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323233;
}
