body,
div,
span,
header,
footer,
nav,
section,
aside,
article,
ul,
dl,
dt,
cite,
dd,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
i,
b,
textarea,
button,
input,
select,
figure,
figcaption {
    padding: 0;
    margin: 0;
    list-style: none;
    font-style: normal;
    text-decoration: none;
    border: none;
    font-family: "Microsoft Yahei", sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
}

//移动端点击高亮
html,
body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

del {
    color: #949497;
}

input {
    border: none;
    outline: none;
    -webkit-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
    border: none;
    outline: none;
}

button {
    border: none;
    outline: none;
}

a {
    text-decoration: none;
    color: #333;
}

li {
    list-style-type: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #CCCCCC !important;
    font-size: 14px;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #CCCCCC;
    font-size: 14px;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #CCCCCC;
    font-size: 14px;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #CCCCCC;
    font-size: 14px;
}

//解决端遮罩层穿透
body.dialog-open {
    position: fixed;
    width: 100%;
}


/* 文字溢出显示... */


/* 单行文本 */

.f-owt {
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}


/* 多行文本 */

.f-owt-two {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-box-orient: vertical;
    // max-height: 44px; //有多行文本的情况
}

.price {
    font-size: 22px;
    font-family: 'BEBAS';
    font-weight: 500;
    color: #FE5C32;
    line-height: 20px;
    span {
        font-size: 14px;
        padding-right: 5px;
    }
}

.price-list {
    font-family: 'BEBAS';
    height: 20px;
    font-size: 18px;
    color: #FE5C32;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    .price-icon {
        font-size: 18px;
        // padding-right: 5px;
        font-family: Arial;
        padding-left: 5px;
    }
    .price-origin {
        position: relative;
        display: inline-block;
        height: 13px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 16px
    }
    .price-origin::before,
    .price-origin:after {
        content: '';
        width: 100%;
        position: absolute;
        right: -3px;
        top: 50%;
    }
    .price-origin::before {
        border-bottom: 2px solid #999999;
    }
}

.org-price {
    font-size: 11px;
    color: #A8A8A8;
    line-height: 16px;
    text-decoration: line-through;
    padding-left: 5px;
}

.title {
    font-size: 17px;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    text-align: center;
}

.big-btn {
    width: 90%;
    background: linear-gradient(137deg, #FF9D5B 0%, #FC6C21 100%);
    box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
    border-radius: 22px;
    font-size: 16px;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
}

.small-btn {
    width: 132px;
    background: linear-gradient(137deg, #FF9D5B 0%, #FE5C32 100%);
    box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
    border-radius: 22px;
    font-size: 16px;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
}

.confirm-btn {
    width: 130px;
    height: 44px;
    background: linear-gradient(270deg, #FF4E45 0%, #FF7753 100%);
    border-radius: 8px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}

.confirm-btn01 {
    opacity: 0.34;
}

.big-btn-01 {
    opacity: .5;
}

.middle-btn {
    width: 236px;
    line-height: 40px;
    background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
    border-radius: 20px;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
}

.middle-btn-01 {
    background: linear-gradient(270deg, #F30406 0%, #FF5B07 100%);
}

.middle-btn-02 {
    background: #C4C4C4;
}

.highlights-text {
    color: #FC6C21 !important;
}