
@import '../../views/detail/index.scss';

button {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
  background: transparent;
}

.hideBgAndPlace {
  /deep/ .place {
    height: 10px;
  }

  /deep/ .bg {
    display: none;
  }
}

.detail-tabs {
  .mock {
    /deep/ .van-tab {
      justify-content: flex-start !important;
      padding-left: 33px !important;
    }

    /deep/ .van-tabs__line {
      transform: translateX(40px) !important;
    }
  }
}

/deep/ .van-tabs__content {
  background: transparent;
}

.pt-box {
  background: url(https://file.huatu.com//static/miniprogram/shop/h5/detail/bg-groupbuy-notice@2x.png)
    center/100% 100% no-repeat;
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
  position: relative;
  overflow: hidden;

  .tit {
    color: #666666;
    line-height: 20px;
    padding-bottom: 20px;
  }

  .txt {
    color: #333333;
    line-height: 20px;
    padding-bottom: 15px;
  }

  /deep/.van-count-down {
    display: inline-block;
  }

  .btn {
    // width: 335px;
    line-height: 40px;
    background: linear-gradient(90deg, #ff9d5b 0%, #fe5c32 100%);
    border-radius: 20px;
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    margin-top: 5px;
  }

  .color {
    color: #fe5c32;
  }

  .bold {
    color: #000000;
    font-weight: bold;
  }
}
